<template>
    <v-data-table
        :headers="headers"
        :items="datas.data"
        :items-per-page="paginationParams.per_page"
        :page="paginationParams.page"
        :server-items-length="datas.total"
        :loading="loading"
        sort-by="id"
        class="border"
        @update:page="changePage"
        @update:items-per-page="changeItemsPerPage"
        :footer-props="footerProps"
        @update:sort-by="sortBy"
        @update:sort-desc="sortDesc"

    >
        <template v-slot:top>
            <v-toolbar flat color="white">
                <v-dialog id="landing-design-data-form" v-model="dialog" fullscreen max-width="500px">
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark class="mb-2" v-on="on">{{ trans('buttons.landingDesignData.newItem') }}</v-btn>
                    </template>
                    <landing-design-data-form v-if="dialogLoaded" :item-id="editedItem" :landing_design_id="landing_design_id" v-on:saved="onSave" v-on:close="close"></landing-design-data-form>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item.id)">mdi-pencil</v-icon>
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:item.coverage="{ item }">
            <v-progress-circular
                v-if="options.landingDesignsTokens"
                :value="translationProgress[item.id]"
                :color="translationProgress[item.id] < 100 ? 'red' : 'green'"
            >
                {{ translationProgress[item.id] }}
            </v-progress-circular>
        </template>
        <template v-slot:item.created_at="{ item }">
            {{ item.created_at | mysqlDate }}
        </template>
        <template v-slot:item.updated_at="{ item }">
            {{ item.updated_at | mysqlDate }}
        </template>


    </v-data-table>
</template>

<script>
    import tableWithPagination from "@/mixins/tableWithPagination";

    export default {
        name: "LandingDesignDataTable",
        components: {
            LandingDesignDataForm: () => import('@/components/admin/landingDesignData/LandingDesignDataForm'),
        },
        props:{
            landing_design_id: {
                type: [Number, String],
                default: '',
            },
        },
        mixins: [tableWithPagination],
        data() {
            return {
                dialog: false,
                dialogLoaded: false,
                datas: {},
                headers: [
                    { text: "id", sortable: false, value: "id" },
                    { text: this.trans("fields.common.language"), value: "language.label", sortable: false },
                    { text: this.trans("fields.common.created_at_m"), value: "created_at", sortable: false },
                    { text: this.trans("fields.common.updated_at_m"), value: "updated_at", sortable: false },
                    { text: this.trans("fields.common.coverage"), value: "coverage", sortable: false },
                    { text: this.trans("fields.common.actions"), value: "actions", sortable: false },
                ],
                paginationParams: {
                    page: 1,
                    per_page: 10,
                },
                url: '',

                editedItem: null,
            }
        },
        created() {
            this.url = this.landing_design_id
                ? this.route('admin.landingDesignDatas.landingDesign.index', this.landing_design_id)
                : this.route('admin.landingDesignDatas.index');
            this.initialize();
            this.loadOptions({languages: true, landingDesigns: true});
        },
        watch: {
            dialog(val) {
                if (val){
                    this.dialogLoaded = true
                } else {
                    this.close();
                }
            }
        },
        methods: {
            onSave(){
                this.close();
                this.initialize();
            },
            editItem(id) {
                this.editedItem = id;
                this.dialog = true;
                this.dialogLoaded = true;
            },
            deleteItem(item) {
                confirm("Are you sure you want to delete this item?") &&
                console.log(['deleted', item]);
            },

            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedItem = null;
                    this.dialogLoaded = false;
                }, 300);
            },


        },
        computed: {
            paginationItems: {
                get(){
                    return this.datas;
                },
                set(v){
                    this.datas = v;
                }
            },
            translationProgress(){
                let progress = {};
                _.each(this.datas.data, (item) => {
                    let itemDataLength = item.data ? _.filter(Object.values(item.data), (token) => !!token ).length : 0;
                    let designTokensLength = Math.max(1, (this.options.landingDesignsTokens[item.landing_design_id] ? this.options.landingDesignsTokens[item.landing_design_id].length : 0 ));
                    progress[item.id] = Math.floor(itemDataLength / designTokensLength * 100 )
                });
                return progress;
            }
        }
    }
</script>

<style scoped>

</style>
